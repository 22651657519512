import * as React from 'react'

import { i18n, useSiteConfig } from '@thg-commerce/enterprise-core'
import {
  ProductBlock,
  ProductBlockList,
} from '@thg-commerce/enterprise-graphql/aurora-schema'
import {
  Maybe,
  ProductList,
  ProductMarketedSpecialOffer,
  ResponsiveProductBlockCarousel,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { getColourSwatches } from '@thg-commerce/enterprise-network/src/transformers/product/colorSwatch'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import {
  BestSellers,
  BestSellersProps,
} from '@thg-commerce/enterprise-widget-best-sellers'
import {
  ButtonWrapper,
  ViewMoreButton,
} from '@thg-commerce/enterprise-widget-section-peek/src/styles'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { PictureProps } from '@thg-commerce/gravity-system'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

import { BaseWidgetProps } from '../types'

type ProductListProduct = ProductList['products'][0]

enum emphasisSelector {
  primary = 'high',
  secondary = 'medium',
  tertiary = 'low',
}

type CarouselProduct = ProductListProduct & {
  price: { price: string; rrp?: string }
  picture: PictureProps
  hoverPicture: PictureProps | null
  inStock: boolean
  icons?: {
    homeDelivery: boolean
    storeAvailable: boolean
    storeDelivery: boolean
    __typename?: string
  }
  isCheckStock?: boolean
  isOrderInStore?: boolean
  colourSwatches?: ColourChoice[]
}

export type ProductBlockCarouselRendererProps = BaseWidgetProps &
  Omit<ResponsiveProductBlockCarousel, 'query' | 'skuList' | 'productList'> & {
    productList: Maybe<
      Omit<ProductList, 'products'> & {
        products: CarouselProduct[]
      }
    >
    productBlockList?:
      | (Omit<ProductBlockList, 'products'> & {
          productBlocks: ProductBlock[]
        })
      | null
    responsiveSkuList: Maybe<CarouselProduct[]>
    widgetIndex: number
  }

interface I18nText {
  closeLabel: string
  closeAriaLabel: string
  rrpText: string
  swatchI18nText: {
    swatchTooltipUnavailable: string
    swatchTooltipCloseButton: string
  }
}

const StyledBestSellers = styled(BestSellers)`
  margin-bottom: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(4)};
  }
`

const PRODUCTS_INITIALLY_VISIBLE = 5

const getMarketedSpecialOfferProperties = (
  closeI18nText: {
    closeLabel: string
    closeAriaLabel: string
  },
  marketedSpecialOffer?: ProductMarketedSpecialOffer | null,
) => {
  if (!marketedSpecialOffer) {
    return undefined
  }
  return {
    title: marketedSpecialOffer.title?.content[0].content,
    description: marketedSpecialOffer.description?.content[0].content,
    landingPageLink: {
      text: marketedSpecialOffer.landingPageLink?.text,
      url: marketedSpecialOffer.landingPageLink?.url,
      openExternally:
        marketedSpecialOffer.landingPageLink?.openExternally || false,
      noFollow: marketedSpecialOffer.landingPageLink?.noFollow || true,
    },
    i18nText: {
      closeI18nText,
    },
    onlyDisplayOfferBadge: true,
  }
}

const getBestSellersProperties = (
  productList: CarouselProduct[],
  i18nText: I18nText,
  alternateProductKeysForSwatches?: string[],
  departmentContentValueForSwatches?: string,
) => {
  return productList?.map((product: CarouselProduct, index) => {
    return {
      ...product,
      title: { value: product.title },
      icons: product.icons,
      isCheckStock: product.isCheckStock || false,
      swatch: product.colourSwatches
        ? {
            shape: SwatchShape.CIRCLE,
            size: SwatchSize.Small,
            colours: getColourSwatches(
              // TODO: CSBOM-405 to revisit the swatches logic implementation
              product,
              alternateProductKeysForSwatches,
              departmentContentValueForSwatches,
            ),
            i18nText: {
              unavailableText: i18nText.swatchI18nText.swatchTooltipUnavailable,
              closeButtonText: i18nText.swatchI18nText.swatchTooltipCloseButton,
            },
          }
        : undefined,
      titleAlignment: HorizontalAlignment.LEFT,
      url: product.url,
      sku: product.sku,
      externalIdentifier: product.externalIdentifier,
      price: {
        ...(product as any).price,
        rrpText: i18nText.rrpText,
      },
      brand: product.brand ?? undefined,
      marketedSpecialOffer: getMarketedSpecialOfferProperties(
        {
          closeLabel: i18nText.closeLabel,
          closeAriaLabel: i18nText.closeAriaLabel,
        },
        product.marketedSpecialOffer,
      ),
      image: {
        urls: {
          largeProduct:
            (product.images?.length > 0 && product.images[0].largeProduct) ||
            '',
          ...product.images[0],
        },
        altText: '',
        isAmp: false,
        lazy: index > PRODUCTS_INITIALLY_VISIBLE - 1,
      },
      picture: {
        style: { width: '100%' },
        ...(product as any).picture,
        alt: product.title,
        lazy: index > PRODUCTS_INITIALLY_VISIBLE - 1,
      },
      hoverPicture: (product as any).hoverPicture && {
        style: { width: '100%' },
        ...(product as any).hoverPicture,
        alt: product.title,
      },
      review: product.reviews && {
        starRating: product.reviews.averageScore,
        numberOfReviews: product.reviews.total,
      },
      inStock: product.inStock,
      content: product?.content,
      leadTime: product.defaultVariant?.leadTime ?? undefined,
      weightGroups: product.defaultVariant?.weightGroups ?? undefined,
      isBookable: product.defaultVariant?.isBookable ?? undefined,
      inStockLocations: product.defaultVariant?.inStockLocations ?? undefined,
      eligibleForFulfilmentMethods:
        product.defaultVariant?.eligibleForFulfilmentMethods ?? undefined,
    }
  })
}

const getProductBlock = (data: ProductBlock[], i18nText) => {
  return data?.map((product: ProductBlock, index) => {
    return {
      ...product,
      title: { value: product.title },
      swatch: product.colourSwatches
        ? {
            shape: SwatchShape.CIRCLE,
            size: SwatchSize.Small,
            colours: product.colourSwatches,
            i18nText: {
              unavailableText: i18nText.swatchI18nText.swatchTooltipUnavailable,
              closeButtonText: i18nText.swatchI18nText.swatchTooltipCloseButton,
            },
          }
        : undefined,
      titleAlignment: HorizontalAlignment.LEFT,
      price: {
        ...product.price,
        rrpText: i18nText.rrpText,
      },
      marketedSpecialOffer: product.marketedSpecialOffer
        ? {
            ...product.marketedSpecialOffer,
            title: product.marketedSpecialOffer.titleText,
            description: product.marketedSpecialOffer.descriptionText,
            i18nText: {
              closeI18nText: {
                closeLabel: i18nText.closeLabel,
                closeAriaLabel: i18nText.closeAriaLabel,
              },
            },
            onlyDisplayOfferBadge: true,
          }
        : undefined,
      picture: {
        ...product.picture,
        style: { width: '100%' },
        lazy: index > PRODUCTS_INITIALLY_VISIBLE - 1,
      },
      hoverPicture: product.hoverPicture && {
        ...product.hoverPicture,
        style: { width: '100%' },
        lazy: false,
      },
      review: product.reviews && {
        starRating: product.reviews?.averageScore,
        numberOfReviews: product.reviews?.total,
      },
    }
  })
}

export const ProductBlockCarouselRenderer = (
  props: ProductBlockCarouselRendererProps,
) => {
  const {
    showPdpLinkWhenOutOfStock,
    alternateProductKeysForSwatches,
    departmentContentValueForSwatches,
  } = useSiteConfig()
  const i18nText: I18nText = {
    closeLabel: i18n('general.modal.close.button.label'),
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    rrpText: i18n('product.price.rrp.text'),
    swatchI18nText: {
      swatchTooltipUnavailable: i18n(
        'product.productoptions.swatch.tooltip.unavailable.text',
      ),
      swatchTooltipCloseButton: i18n(
        'product.productoptions.swatch.tooltip.close.text',
      ),
    },
  }

  const productBlocks = props?.productBlockList
    ? getProductBlock(props?.productBlockList.productBlocks, i18nText)
    : props?.productList
    ? getBestSellersProperties(
        props?.productList?.products,
        i18nText,
        alternateProductKeysForSwatches,
        departmentContentValueForSwatches,
      )
    : props?.responsiveSkuList
    ? getBestSellersProperties(
        props?.responsiveSkuList,
        i18nText,
        alternateProductKeysForSwatches,
        departmentContentValueForSwatches,
      )
    : null

  if (productBlocks) {
    const bestSellersProps: BestSellersProps = {
      showPdpLinkWhenOutOfStock,
      productBlocks,
      widgetIndex: props.widgetIndex,
      title: props.title ? { text: props.title } : undefined,
    }

    return (
      <React.Fragment>
        <StyledBestSellers {...bestSellersProps} />
        {props.viewAllButtonUrl && props.viewAllButtonText && (
          <ButtonWrapper colSpan={12}>
            <ViewMoreButton
              emphasis={emphasisSelector[props.viewAllButtonStyle || 'medium']}
              href={props.viewAllButtonUrl}
              renderedAs={'a'}
            >
              {props.viewAllButtonText}
            </ViewMoreButton>
          </ButtonWrapper>
        )}
      </React.Fragment>
    )
  }

  return null
}
